@media (max-width: 900px) {
  .MuiTablePagination-toolbar {
    padding: 0 !important;
  }

  .MuiTablePagination-actions {
    order: -3;
    margin-left: 0 !important;
    padding-right: 24px;
  }

  .MuiTablePagination-displayedRows {
    order: -2;
    padding-right: 24px;
  }

  .MuiTablePagination-spacer {
    order: -1;
    display: none;
  }
}
