@import '../../variables';

.side-menu-layout {
  .main-content {
    padding-top: $toolbar-spacing;
    min-height: 100dvh;
    display: flex;
    flex-direction: column;
  }

  .menu-button {
    margin-right: $double-spacing !important;
  }

  .title-root {
    flex-grow: 1;
    display: flex;
    align-items: baseline;
    gap: $half-spacing;
  }

  @media (min-width: 601px) {
    .main-content {
      padding-top: $toolbar-spacing-desktop;
    }
  }

  .user-panel {
    display: flex;
    align-items: center;

    .text {
      display: flex;
      flex-direction: column;
      margin-left: $double-spacing;
    }
  }
}
