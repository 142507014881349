body {
  width: 100%;
  /*
    Somehow 100vh always shows the vertical scrollbar
    which is disappearing after a few seconds. Weird behaviour.
  */
  min-height: 99.5vh; 
}

#root {
  min-height: inherit;
}
