@import '../../../variables';

.full-page-view {
  padding: 2 * $spacing;
  flex: 1;
  display: flex;
  flex-direction: column;

  .breadcrumb-container {
    display: none;
    margin-bottom: 2 * $spacing;
  }

  @media (min-width: 901px) {
    .breadcrumb-container {
      display: block;
    }
  }
}
